import { graphql } from 'gatsby';
import React, { FC } from 'react';

import App from '../components/App';
import CategoryTitle from '../components/CategoryTitle';
import Pagination from '../components/Pagination';
import Posts from '../components/Posts';

// markup
const LifeArticlesList: FC<{ data: any }> = ({ data }) => {
  const { allMarkdownRemark } = data;
  const { edges, pageInfo } = allMarkdownRemark;

  const { currentPage, pageCount } = pageInfo;

  return (
    <App>
      <CategoryTitle category="Life" />
      <Posts edges={edges} />
      <Pagination
        currentPage={currentPage}
        pageCount={pageCount}
        url="category/life"
      />
    </App>
  );
};

export default LifeArticlesList;

export const pageQuery = graphql`
  query lifePageQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { slug: { regex: "/life/" } } }
      limit: $limit
      skip: $skip
    ) {
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
      edges {
        node {
          id
          excerpt(pruneLength: 100)
          frontmatter {
            slug
            date(formatString: "MMMM DD, YYYY", locale: "en")
            title
            category
            tag
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 450
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;
