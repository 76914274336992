import React, { FC } from 'react';

import Article from '../Article';

const Posts: FC<{ edges: any }> = ({ edges }) => {
  const aPost = edges
    .filter(
      (edge: { node: { frontmatter: { title: string } } }) =>
        !!edge.node.frontmatter.title,
    )
    .map((edge: { node: { id: number } }) => (
      <Article key={edge.node.id} post={edge.node} />
    ));

  if (aPost?.length > 0) {
    return (
      <div>
        <div className="grid md:grid-cols-2 col-span-full mb-6 md:mb-0">
          {aPost}
        </div>
      </div>

    );
  }

  return <div className="no-posts">No article available</div>;
};

export default Posts;
