import { Link } from 'gatsby';
import React, { FC } from 'react';

const Pagination: FC<{
  pageCount: number;
  currentPage: number;
  url: string;
}> = ({ pageCount, currentPage, url }) => {
  const isFirst = 1 === currentPage;
  const isLast = currentPage === pageCount;
  const prevPage = 1 === currentPage - 1 ? '' : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();
  if (pageCount && pageCount > 1) {
    return (
      <div className="md:w-1/2 m-auto  text-center mb-5">
        <ul className="grid grid-cols-3 grid-rows-1 ">
          {!isFirst && (
            <div className="col-start-1">
              <Link
                className="text-2xl md:text-base"
                rel="prev"
                to={`/${url}/${prevPage}`}
              >
                ‹{' '}
                <span className="hidden md:inline md:border-b md:border-transparent md:hover:border-black">
                  Previous
                </span>
              </Link>
            </div>
          )}
          <div className="col-start-2">
            {Array.from({ length: pageCount }, (_, i) => (
              <li
                key={`pagination-number${i + 1}`}
                className="text-2xl md:text-base text-center p-3 "
              >
                <Link
                  className={` border-b border-transparent hover:border-black pb-1
               ${i + 1 === currentPage ? 'border-b border-black pb-1' : ''}`}
                  to={`/${url}/${0 === i ? '' : i + 1}`}
                >
                  {i + 1}
                </Link>
              </li>
            ))}
          </div>
          {!isLast && (
            <div className="col-start-3  ">
              <Link
                className="text-2xl md:text-base"
                rel="next"
                to={`/${url}/${nextPage}`}
              >
                <span className="hidden md:inline md:border-b md:border-transparent md:hover:border-black">
                  Next
                </span>{' '}
                ›
              </Link>
            </div>
          )}
        </ul>
      </div>
    );
  }

  return null;
};

export default Pagination;
