import React, { FC } from 'react';

const CategoryTitle: FC<{ category: string }> = ({ category }) => (
  <div>
    <div className="p-8 text-2xl text-center">
      <p className="font-category">Category</p>
      <p className="font-bold capitalize">{category}</p>
    </div>
    <hr />
  </div>
);

export default CategoryTitle;
