/* eslint-disable max-len */
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { FC } from 'react';

// markup

const Article: FC<{ post: any }> = ({ post }) => {
  const image = getImage(post.frontmatter.featuredImage);

  return (
    <div
      key={post.frontmatter.slug}
      className="flex align-top font-article mt-12 md:mb-12 justify-center  "
    >
      <div className="w-11/12 md:w-3/4 grid  grid-cols-1">
        <Link to={post.frontmatter.slug}>
          <div className="w-full md:w-4/5 m-auto">
            {image ? (
              <GatsbyImage
                alt={post.frontmatter.title}
                className="w-full"
                image={image}
              />
            ) : null}

            <div className=" grid grid-cols-2 text-sm md:text-x ">
              <div className="text-left lowercase">
                {post.frontmatter.category} <span>•</span>{' '}
                {post.frontmatter.tag}
              </div>
              <div className="text-right">{post.frontmatter.date}</div>
            </div>
          </div>
          <div >
            <div className=" row-start-1 col-span-full ">
              <div className="text-2xl text-center font-article font-bold ">
                {post.frontmatter.title}
              </div>
            </div>
            <div className="md:col-span-full md:row-start-2  md:text-base text-left ">
              {post.excerpt}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Article;
